// Load Styles
import "../scss/main.scss";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";
import $ from "jquery";
import ScrollReveal from "scrollreveal";


// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
let currentLanguage = "hr";
$(function () {


  if (window.location.href.indexOf('/en') > 0) {
    let lang = 'en';
    if (lang == "en") {
      let elements = $(".language-picker-dropdown").find("[data-lang='en']");
      if (elements.length > 0) {
        $('#language-button').html($(elements[0]).html());
      }
      currentLanguage = "en";
    } else {
      currentLanguage = "hr";
    }
  }
  $(".language-picker-option").on("click", (e) => {
    let language = $(e.target).data('lang');
    if (language && currentLanguage != language) {
      currentLanguage = language;
      window.location.href = '/' + currentLanguage;
    }
  });
  ScrollReveal().watch = function (target, onEnter, onExit) {
    onExit = onExit || function () { };

    if (typeof onEnter === "function" && typeof onExit === "function") {
      let noEffect = {
        beforeReset: onExit,
        beforeReveal: onEnter,
        opacity: null,
        viewOffset: {
          bottom: 400,
        },
      };
      this.reveal(target, noEffect);
    } else {
      throw new Error("Watch received invalid arguments.");
    }
  };
  ScrollReveal().watch2 = function (target, onEnter, onExit) {
    onExit = onExit || function () { };

    if (typeof onEnter === "function" && typeof onExit === "function") {
      let noEffect = {
        beforeReset: onExit,
        beforeReveal: onEnter,
        opacity: null,
        delay: 5000,
      };
      this.reveal(target, noEffect);
    } else {
      throw new Error("Watch received invalid arguments.");
    }
  };
  ScrollReveal().watch(
    ".is-animated",
    function onEnter(el) {
      let timeout = $(el).data("animation-timeout") || 0;
      setTimeout(() => {

        let svgs = $(el).find('object');
        let isOk = false;
        for (let i = 0; i < svgs.length; i++) {
          if (svgs[i] && svgs[i].contentWindow && svgs[i].contentWindow.onDisplay) {
            svgs[i].contentWindow.onDisplay();
            isOk = true;
          }
        }
        if (!isOk) {
          el.classList.add("animate__animated");
          el.classList.add("animate__bounce");
        }
      }, timeout * 1000);
    },
    function onExit(el) { }
  );
  ScrollReveal().watch2(
    ".animated-preview",
    function onEnter(el) {
      let timeout = $(el).data("animation-timeout") || 0;
      setTimeout(() => {
        el.classList.add("opacity-100");
        el.classList.add("normal-width");
      }, timeout * 1000);
    },
    function onExit(el) { }
  );
  loadPart();
  $(window).on('resize', () => {
    loadPart();
  });
});

let loadPart = async () => {
  tsParticles
    .loadJSON("intro-particles", "/lib/particlesjs-config.json")
    .then((container) => {

    })
    .catch((error) => {
      console.error(error);
    });
}